html,
body,
#root
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ivory;
}

.ctr
{
    position: absolute;
    bottom:10%;
    left:0;
    width: 100%;
}

.raw
{
    display: flex;
    justify-content: center;
}

.key
{
    width: 40px;
    height: 40px;
    margin: 4px;
    border: 2px solid #ffffff;
    background: #ffffff44;
}

.large
{
    width: 144px;
}

.active
{
    background: #ffffff99;
}

.interface
{
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    font-family: 'Bebas Neue', cursive;
}

.time
{
    position: absolute;
    top:15%;
    left:0;
    width: 100%;
    color:#ffffff;
    font-size: 6vh;
    background: #00000033;
    padding-top: 5px;
    text-align: center;
}

.restart
{
    display: flex;
    justify-content: center;
    position: absolute;
    top:40%;
    left:0;
    width: 100%;
    color: #ffffff;
    font-size: 80px;
    background: #00000033;
    padding-top: 10px;
    pointer-events: auto;
    cursor: pointer;
}


